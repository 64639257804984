import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';


const NotFoundPage = () => {


    return (
        <>
            <Header />
            {/* Error 404 Section Start Here */}
            <div className="error-container">
                <h1 className="error-code">404</h1>
                <p className="error-message">Oops! Page not found.</p>
                <p className="error-description">The requested page does not exist. Please check the URL or go back to the homepage.</p>
                <Link to="/" className="btn">Go to Homepage</Link>
            </div>
            {/* Error 404 Section End Here */}
            {/* Footer Included Here */}
            <Footer />
            {/* Footer Included Here */}
        </>
    )
}
export default NotFoundPage;