// apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchGlossary = createAsyncThunk('data/glossary', async () => {
  const response = await fetch(
    `https://webtrans.developer-ourbase-camp.com/api/glossary`
  );
  const fetchedData = await response.json();
  return fetchedData;
});

const glossarySlice = createSlice({
  name: 'glossary',
  initialState: {
    data: [],
    isLoading: false,
    isError: null
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlossary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGlossary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchGlossary.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = true;
        state.isError = action.error.message;
      });
  },
});

export default glossarySlice.reducer;
