import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../Constant/Index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getNewsData } from "../../redux/News/newsSlice";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet";

const News = () => {
  const dispatch = useDispatch();
  const newsData = useSelector((state) => state?.news?.data);
  const loadingState = useSelector((state) => state?.news?.isLoading);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    dispatch(getNewsData(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  // const { data } = newsData;
  // const allNews = data?.["News"];
  const allNews = newsData?.data?.data;
  const totalNewsCount = newsData?.data?.total;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const sliceData = allNews?.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const ArrowIcon = () => {
    return <img src={ArrowRightIcon} alt="right-icon" />;
  };
  return (
    <>
      <Helmet>
        <title>News | Webtrans Logistics INC</title>
      </Helmet>
      <Header />
      <section className="news-section news-page">
        <div className="container">
          <div className="news-start-here">
            <div className="top-heading text-center">
              <h2 className="lg-heading secondary-text animate__animated animate__bounceInLeft animate__delay-1s">
                Latest News
              </h2>
            </div>
            <div className="news-list">
              <div className="row">
                {loadingState &&
                  Array(6)
                    .fill(0)
                    .map((_, i) => (
                      <div className="col-lg-4" key={i}>
                        <Skeleton height={10} count={10} />
                      </div>
                    ))}
                {!loadingState &&
                  sliceData &&
                  sliceData?.length > 1 &&
                  sliceData?.map((single) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 mt-3"
                      key={single?.id}
                    >
                      <div className="single-news animate__animated animate__backInLeft animate__delay-2s">
                        <figure className="news-image">
                          <img
                            src={single?.news_image_url}
                            alt=""
                            className="the-image w-100"
                          />
                        </figure>
                        <div className="news-body">
                          <h5 className="xs-heading crop-1-lines">
                            {single?.title}
                          </h5>
                          <p
                            className="text crop-2-lines"
                            dangerouslySetInnerHTML={{
                              __html: single?.description_one,
                            }}
                          ></p>
                          <span className="date">
                            {new Date(single?.created_at).toLocaleDateString()}
                          </span>
                          <Link
                            to={`/news-details/${single?.slug}`}
                            className="news-link"
                          >
                            <img src={ArrowRightIcon} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="web-paginations mt-5">
              <ReactPaginate
                breakLabel="..."
                nextLabel={<ArrowIcon />}
                pageCount={Math.ceil(totalNewsCount / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName="pagination gap-3"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                nextLinkClassName="page-link next-arrow"
                previousLinkClassName="page-link next-arrow"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default News;
