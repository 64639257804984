import React, { useState } from "react";
import { ChooseUsImg } from "../../Constant/Index";
const ChooseUs = ({ data }) => {
  const missionData = data?.["mission-page"];
  const vissionData = data?.["vision-page"];
  const valuesData = data?.["value-page"];

  const [ActiveFilter, setActiveFilter] = useState(1);
  const filterList = ["Our Mission", "Our Vision", "Our Value"];
  const filterData = [missionData, vissionData, valuesData];

  const changeFilter = (filterIndex) => {
    setActiveFilter(filterIndex);
  };
  return (
    <section className="choseus-section">
      <div className="container">
        <div className="col-xl-10 mx-auto">
          <div className="chose-us-start">
            <div className="row">
              <div className="col-lg-9 mx-auto col-md-12">
                <div className="chose-us-text">
                  <div className="top-heading animate__animated animate__bounceInUp  animate__delay-2s">
                    <span className="small-top-heading">Why Choose Us?</span>
                    <h2 className="md-heading">
                      We Create opportunity To Reach Potential.
                    </h2>
                  </div>
                  <ul className="filter-options mx-auto">
                    {filterList.map((filter, index) => (
                      <li className="single-filter" key={index}>
                        <button
                          className={`filter-btn ${
                            index == ActiveFilter ? " active" : ""
                          }`}
                          onClick={() => changeFilter(index)}
                        >
                          {filter}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="filter-data pt-4 animate__animated animate__bounceInRight  animate__delay-2s">
                    {filterData.map((data, index) => (
                      <div key={index}>
                        {ActiveFilter === index && (
                          <div className="single-data" key={index}>
                            <p className="text">{data?.content_one}</p>
                            <div className="text-image">
                              <div className="row">
                                <div className="col-sm-6">
                                  <figure className="data-image">
                                    <img
                                      src={`https://webtrans.developer-ourbase-camp.com/uploads/${data?.image}`}
                                      alt=""
                                      className="the-image w-100"
                                    />
                                  </figure>
                                </div>
                                <div className="col-sm-6 mt-2">
                                  <p className="text">{data?.content}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
