import { Link } from "react-router-dom";

const ServicesSec = ({ data }) => {
  return (
    <section className="services-section">
      <div className="container">
        <div className="services-list">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-6 mt-5 px-xl-3">
              <div className="service-blue-box primary-text animate__animated animate__bounceInRight  animate__delay-2s">
                <span className="small-top-heading uppercase-text">
                  {data?.["service-page"]?.page_title}
                </span>
                <h3 className="md-heading">
                  {data?.["service-page"]?.service_title}
                </h3>
                <p
                  className="crop-3-lines text m-0"
                  dangerouslySetInnerHTML={{
                    __html: data?.["service-page"]?.content,
                  }}
                ></p>
              </div>
            </div>
            {data?.service &&
              data?.service?.length > 1 &&
              data?.service?.map((service, i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 mt-5 px-xl-3"
                  key={i}
                >
                  <div className="single-service animate__animated animate__bounceInDown  animate__delay-2s">
                    <h4 className="sm-heading">
                      {service?.title.length > 30
                        ? service?.title.substring(0, 30)
                        : service?.title}
                    </h4>
                    <div className="the-text">
                      <p className="text crop-2-lines">
                        {/* {service?.description_one} */}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: service?.description_one?.substring(0, 100),
                          }}
                        ></span>
                        <Link
                          to={`/service-detail/${service?.slug}`}
                          className="read-more"
                        >
                          Read More
                        </Link>
                      </p>
                    </div>
                    <figure className="serivce-img">
                      <img
                        src={service?.service_image_url}
                        alt=""
                        className="the-img w-100"
                      />
                    </figure>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSec;
