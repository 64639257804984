import React, { useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "react-range-slider-input/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeData,
  getData,
  getLoadingState,
  getError,
} from "../../redux/Home/homeSlice";
import HomeBanner from "../../sections/Home/Banner";
import AboutSec from "../../sections/Home/AboutSec";
import ServicesSec from "../../sections/Home/ServicesSec";
import ChooseUs from "../../sections/Home/ChooseUs";
import TestimonialsSec from "../../sections/Home/TestimonialsSec";
import NewsSec from "../../sections/Home/NewsSec";
import ContactSec from "../../sections/Home/ContactSec";
import { Helmet } from "react-helmet";

const Home = () => {
  const dispatch = useDispatch();
  const homeData = useSelector(getData);
  const loadingState = useSelector(getLoadingState);
  const errorState = useSelector(getError);

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  const { data } = homeData;

  return (
    <>
    <Helmet>
      <title>Webtrans Logistics INC</title>
    </Helmet>
      <Header />
      <HomeBanner data={data} isLoading={loadingState} />
      <AboutSec data={data} isLoading={loadingState}/>
      <ServicesSec data={data} isLoading={loadingState} />
      <ChooseUs data={data} isLoading={loadingState}/>
      <TestimonialsSec data={data} isLoading={loadingState} />
      <NewsSec data={data} isLoading={loadingState} />
      <ContactSec data={data} isLoading={loadingState} />
      <Footer />
    </>
  );
};
export default Home;
