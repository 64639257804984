import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { SeoSignImg } from "../../Constant/Index";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getPageData } from "../../redux/PagesData/pagesSlice";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const AboutUs = () => {
  const dispatch = useDispatch();
  const selectPageData = useSelector((state) => state?.pagesData?.data);
  const isLoading = useSelector((state) => state?.pagesData?.isLoading);
  useEffect(() => {
    dispatch(getPageData("about-us"));
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`${selectPageData?.data?.page_title}`} | Webtrans Logistics INC
        </title>
      </Helmet>
      <Header />
      {/* AboutUs Section Start Here */}
      <section className="about-exp-ceo pt-5 mt-4">
        <div className="container">
          <div className="top-heading text-center animate__animated animate__bounceInLeft animate__delay-1s">
            <h2 className="lg-heading">{selectPageData?.data?.page_title}</h2>
          </div>
          <div className="section-start pt-5">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 order-1 order-lg-0 mt-5 mt-lg-0 ">
                {isLoading ? (
                  <Skeleton count={10} />
                ) : (
                  <div className="ceo-message-text pe-lg-5 animate__animated animate__bounceInLeft animate__delay-1s">
                    <h2 className="md-heading ">
                      {selectPageData?.data?.about_title_one_top}
                    </h2>
                    <p>{selectPageData?.data?.about_content_one_top}</p>
                    <figure className="signature-img">
                      <img src={SeoSignImg} className="img-fluid" alt="..." />
                      <figcaption>
                        {selectPageData?.data?.ceo_designation
                          ? selectPageData?.data?.ceo_designation
                          : "President and CEO of Webtrans Logistics Inc."}
                      </figcaption>
                    </figure>
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                {isLoading ? (
                  <Skeleton height={400} />
                ) : (
                  <div className="experience-right-data animate__animated animate__bounceInRight animate__delay-1s">
                    <figure className="experience-images order-1">
                      <img
                        src={`https://webtrans.developer-ourbase-camp.com/uploads/${selectPageData?.data?.about_image_one_top}`}
                        alt=""
                        className="top-left-img"
                      />
                      <img
                        src={`https://webtrans.developer-ourbase-camp.com/uploads/${selectPageData?.data?.about_image_two_top}`}
                        alt=""
                        className="bottom-right-img"
                      />
                    </figure>
                    <div className="experience-box primary-text order-0 mb-3 mb-md-0">
                      <div className="headings">
                        <h2 className="md-heading">
                          {selectPageData?.data?.about_image_three_top}+
                        </h2>
                        <h6 className="xs-heading small">Year</h6>
                      </div>
                      <p className="text crop-2-lines">
                        We Have More than years of Experience
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* AboutUs Section End Here */}
      {/* Mission and Goals Section Start here */}
      <section className="mission-goals">
        <div className="container">
          <div className="mission-golas-start animate__animated animate__bounceInLeft animate__delay-2s">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                {isLoading ? (
                  <Skeleton height={400} />
                ) : (
                  <figure className="main-img animate__animated animate__bounceInRight animate__delay-3s">
                    <img
                      src={`https://webtrans.developer-ourbase-camp.com/uploads/${selectPageData?.data?.about_image_two_center}`}
                      alt=""
                      className="theimg w-100 img-fluid"
                    />
                  </figure>
                )}
              </div>
              <div className="col-lg-6 col-md-12">
                {isLoading ? (
                  <Skeleton count={10} />
                ) : (
                  <div className="mission-goal-text primary-text mt-4">
                    <h2 className="md-heading captalize-text">
                      {selectPageData?.data?.about_title_two_center}
                    </h2>
                    <div className="text-here">
                      <p className="text">
                        {selectPageData?.data?.about_content_two_center}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Mission and Goals Section End here */}
      {/* Company Section Start Here */}
      <section className="company-section">
        <div className="container">
          <div className="company-start">
            <div className="row align-items-start">
              <div className="col-md-6">
                {isLoading ? (
                  <Skeleton count={15} />
                ) : (
                  <div className="comany-text animate__animated animate__bounceInRight animate__delay-1s">
                    <div className="top-txt">
                      <h2 className="md-heading">
                        {selectPageData?.data?.about_title_three_bottom}
                      </h2>
                      <p className="md-text">
                      {selectPageData?.data?.about_content_three_bottom}
                      </p>
                    </div>
                    <ul className="yearly-update-list">
                      {selectPageData?.data?.years?.map((year, i) => (
                        <li className="single-year" key={i}>
                          <span className="year-name">{year?.year}</span>
                          <p className="text">{year?.content}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-5 mt-lg-0">
                {isLoading ? (
                  <Skeleton height={400} />
                ) : (
                  <figure className="company-img animate__animated animate__bounceInLeft animate__delay-1s">
                    <img
                      src={`https://webtrans.developer-ourbase-camp.com/uploads/${selectPageData?.data?.about_image_three_bottom}`}
                      alt=""
                      className="the-img w-100 img-fluid"
                    />
                  </figure>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Company Section End Here */}
      {/* Footer Included Here */}
      <Footer />
      {/* Footer Included Here */}
    </>
  );
};
export default AboutUs;
