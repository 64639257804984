import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Services from "../Pages/Services/Services";
import ServiceDetials from "../Pages/Services/ServiceDetials";
import NotFoundPage from "../Pages/ErrorPages/NotFoundPage";
import TermsConditions from "../Pages/Terms-Conditions/TermsConditions";
import ShipperInsurance from "../Pages/ShipperInsurance/ShipperInsurance";
import ContactUs from "../Pages/ContactUs/ContactUs";
import News from "../Pages/News/News";
import NewsDetails from "../Pages/News/NewsDetails";
import Tools from "../Pages/Tools/Tools";
import AboutUs from "../Pages/AboutUs/AboutUs";
import ScrollToTop from "../Components/ScrollToTop";
const PublicRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path={"/service-detail/:slug"} element={<ServiceDetials />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/shipping-insurance" element={<ShipperInsurance />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/news" element={<News />} />
        <Route path="/news-details/:slug" element={<NewsDetails />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
};
export default PublicRoutes;
