import React, { useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getPageData } from "../../redux/PagesData/pagesSlice";
import Skeleton from "react-loading-skeleton";

const TermsConditions = () => {
  const dispatch = useDispatch();
  const selectPageData = useSelector((state) => state?.pagesData?.data);
  const isLoading = useSelector((state) => state?.pagesData?.isLoading);
  useEffect(() => {
    dispatch(getPageData("terms-and-conditions"));
  }, []);

  const data = selectPageData?.data;

  return (
    <>
      <Helmet>
        <title>
          {`${data ? data?.term_title : ""}`} | Webtrans Logistics INC
        </title>
      </Helmet>
      <Header />
      {isLoading ? (
        <div className="container my-5">
          <Skeleton count={50} />
        </div>
      ) : (
        <section className="terms-section pt-3 pt-lg-5 pb-5 mt-3">
          <div className="container">
            <div className="terms-start">
              <div className="top-headings">
                <h2 className="lg-heading text-center mb-4">
                  {data?.page_title}
                </h2>
                <p className="lg-text">{data?.term_title}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.term_content,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
};
export default TermsConditions;
