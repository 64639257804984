import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Get Contact Details
export const fetchContactData = createAsyncThunk("data/contact", async () => {
  try {
    const response = await fetch(
      "https://webtrans.developer-ourbase-camp.com/api/office-address"
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error, "Error Fetching Data");
  }
});

export const contactSlice = createSlice({
  name: "contactUs",
  initialState: {
    isLoading: false,
    isError: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContactData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchContactData.rejected, (state, action) => {
        state.isLoading = true;
        state.isError = action.error.message;
      });
  },
});


export default contactSlice.reducer;

export const getContactData = (state) => state?.contactUs?.data;
