import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCurrencyExchangeData = createAsyncThunk(
  "currency/exchange",
  async ({ from, to, amount }) => {
    try {
      const response = await fetch(
        `https://webtrans.developer-ourbase-camp.com/api/currency/convert?from=${from}&to=${to}&amount=${amount}`
      );
      const fetchedData = await response.json();
      return fetchedData;
    } catch (error) {
      throw error;
    }
  }
);

export const currencyExchange = createSlice({
  name: "currencyExchange",
  initialState: {
    exchangeRate: null,
    convertedAmount: null,
    loading: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencyExchangeData.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getCurrencyExchangeData.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.exchangeRate = action.payload;
        state.convertedAmount = action.payload;
      })
      .addCase(getCurrencyExchangeData.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default currencyExchange.reducer;
