import { parseISO, formatDistanceToNow } from "date-fns";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsSec = ({ data }) => {
  const [sliderRef, setSliderRef] = useState(null);

  const timestamp = data?.testimonial?.map((time) => time?.created_at);
  let timeAgo = "";

  if (timestamp && timestamp.length > 0) {
    timeAgo = timestamp.map((times) => {
      const date = parseISO(times);
      const timePeriod = formatDistanceToNow(date);
      return `${timePeriod} ago`;
    });
  }

  const sliderSettings = {
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows : false
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows : false
        },
      },
    ],
  };
  return (
    <section className="testimonial-section">
      <div className="container">
        <div className="testimonials-list">
          <Slider ref={setSliderRef} {...sliderSettings}>
            <div className="testimonial-text animate__animated animate__bounceInUp  animate__delay-2s">
              <span className="top-small-heading uppercase-text">
                {data?.["testimonial-page"]?.page_title}
              </span>
              <h3 className="md-heading captalize-text">
                {data?.["testimonial-page"]?.testimonial_title}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.["testimonial-page"]?.content,
                }}
              ></p>
              {/* <a href="" className="btn">
                Explore More
              </a> */}
            </div>
            {data?.testimonial &&
              data?.testimonial?.length > 0 &&
              data?.testimonial?.map((item, i) => (
                <div
                  className="single-testimonials animate__animated animate__bounceInUp  animate__delay-2s"
                  key={item?.id}
                >
                  <div className="top-bar ">
                    <div className="user-profile">
                      <figure className="user-image">
                        <img
                          src={item?.testimonial_image_url}
                          alt=""
                          className="the-image"
                        />
                      </figure>
                      <div className="user-name">
                        <h6 className="xs-heading crop-1-lines">
                          {item?.name}
                        </h6>
                        <span className="role">
                          {item?.type?.substring(0, 12)}
                        </span>
                      </div>
                    </div>
                    <span className="posting-time">Posted {timeAgo[i]}</span>
                  </div>
                  <div className="testimonial-body">
                    <p
                      className="text m-0"
                      dangerouslySetInnerHTML={{
                        __html: item?.content,
                      }}
                    ></p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSec;
