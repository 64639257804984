import { Link } from "react-router-dom";
import {
  ExperienceImg1,
  ExperienceImg2,
  GlobalIcon,
  LocalIcon,
} from "../../Constant/Index";
import Skeleton from "react-loading-skeleton";

const AboutSec = ({ data, isLoading }) => {
  return (
    <section className="experience-section">
      <div className="container">
        <div className="experience-start">
          <div className="row">
            <div className="col-lg-6 col-md-12 ">
              {isLoading ? (
                <Skeleton height={400} />
              ) : (
                <div className="experience-right-data animate__animated animate__bounceInLeft  animate__delay-2s">
                  <figure className="experience-images order-1">
                    <img
                      src={data?.about?.about_image_one_top ? `https://webtrans.developer-ourbase-camp.com/uploads/${data?.about?.about_image_one_top}` : ExperienceImg1}
                      alt=""
                      className="top-left-img"
                    />
                    <img
                      src={data?.about?.about_image_two_top ? `https://webtrans.developer-ourbase-camp.com/uploads/${data?.about?.about_image_two_top}` : ExperienceImg2}
                      alt=""
                      className="bottom-right-img"
                    />
                  </figure>
                  <div className="experience-box primary-text order-0 mb-3 mb-md-0">
                    <div className="headings">
                      <h2 className="md-heading">
                        {data?.about?.about_image_three_top}+
                      </h2>
                      <h6 className="xs-heading small">Year</h6>
                    </div>
                    <p className="text crop-2-lines">
                      We Have More than years of Experience
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-6 col-md-12 ps-xxl-4">
              {isLoading ? (
                <Skeleton count={18} />
              ) : (
                <div className="experience-about animate__animated animate__bounceInRight  animate__delay-1s">
                  <div className="top-text">
                    <span className="top-small-heading uppercase-text">
                      About
                    </span>
                    <h3 className="md-heading w-75">
                      {data?.about?.about_title_one_top}
                    </h3>
                    {/* <p className="text exp-color">
                   {data?.about?.about_content_one_top}
                  </p> */}
                  </div>
                  <div className="experience-in mt-3">
                    <div className="row">
                      <div className="col-md-6 pe-xxl-4 order-1 order-md-0 mt-4 mt-md-0">
                        <div className="the-text">
                          <p className="text exp-color">
                            {data?.about?.about_content_one_top?.length > 700
                              ? `${data?.about?.about_content_one_top?.substring(
                                  0,
                                  700
                                )}...`
                              : data?.about?.about_content_one_top}
                          </p>
                          <Link
                            to="/aboutus"
                            className="btn mt-4 animate__animated animate__zoomInDown animate__delay-2s"
                          >
                            Explore More
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6 ps-0 order-0 order-md-1">
                        <div className="experience-box">
                          <div className="single-box">
                            <figure>
                              <img
                                src={
                                  data?.about?.global_service_image
                                    ? `https://webtrans.developer-ourbase-camp.com/uploads/${data?.about?.global_service_image}`
                                    : GlobalIcon
                                }
                                alt=""
                                className="icon"
                              />
                            </figure>
                            <div className="the-data">
                              <h6 className="xs-heading small mb-0">
                                {data?.about?.global_service_heading}
                              </h6>
                              <p className="text mb-0">
                                {data?.about?.global_service_content}
                              </p>
                            </div>
                          </div>
                          <div className="single-box">
                            <figure>
                              <img
                                src={
                                  data?.about?.global_service_image
                                    ? `https://webtrans.developer-ourbase-camp.com/uploads/${data?.about?.global_service_image}`
                                    : LocalIcon
                                }
                                alt=""
                                className="icon"
                              />
                            </figure>
                            <div className="the-data">
                              <h6 className="xs-heading small mb-0">
                                {data?.about?.local_service_heading}
                              </h6>
                              <p className="text mb-0">
                                {data?.about?.local_service_content}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSec;
