import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { OceanShipImg, AirShipImg } from "../../Constant/Index";
// SLick Slider Lirbaries Imported Here
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getPageData } from "../../redux/PagesData/pagesSlice";
import Skeleton from "react-loading-skeleton";

const ShipperInsurance = () => {
  const dispatch = useDispatch();
  const selectPageData = useSelector((state) => state?.pagesData?.data);
  const isLoading = useSelector((state) => state?.pagesData?.isLoading);
  useEffect(() => {
    dispatch(getPageData("shippers-insurance"));
  }, []);

  const data = selectPageData?.data;
  return (
    <>
      <Helmet>
        <title>Shipper's Insurance | Webtrans Logistics INC</title>
      </Helmet>
      <Header />
      {/* Terms Conditions Section Start Here */}
      {isLoading ? (
        <div className="container my-5">
          <Skeleton count={50} />
        </div>
      ) : (
        <section className="insurance-insurance pt-3 pt-lg-5 pb-5 mt-3">
          <div className="container">
            <div className="ship-insurance-start">
              <div className="top-headings">
                <h2 className="md-heading text-center">{data?.page_title}</h2>
                <p className="lg-text">{data?.content}</p>
              </div>
              <div className="shipment-list">
                <div className="row">
                  <div className="col-md-6 mt-5 px-md-4">
                    <figure className="shipping-image">
                      <img
                        src={`https://webtrans.developer-ourbase-camp.com/uploads/${data?.shipper_image_one}`}
                        alt=""
                        className="the-img w-100 img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="col-md-6 mt-3 mt-md-5 px-md-4">
                    <div
                      className="shipping-text h-100 d-flex flex-column justify-content-center"
                      dangerouslySetInnerHTML={{
                        __html: data?.shipper_content_one,
                      }}
                    ></div>
                  </div>

                  <div className="col-md-6 mt-3 mt-md-5 px-md-4">
                    <div
                      className="shipping-text h-100 d-flex flex-column justify-content-center"
                      dangerouslySetInnerHTML={{
                        __html: data?.shipper_content_two,
                      }}
                    ></div>
                  </div>
                  <div className="col-md-6 mt-5 px-md-4">
                    <figure className="shipping-image">
                      <img
                        src={`https://webtrans.developer-ourbase-camp.com/uploads/${data?.shipper_image_two}`}
                        alt=""
                        className="the-img w-100 img-fluid"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};
export default ShipperInsurance;
