import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewsData } from "../../redux/News/newsSlice";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const NewsDetails = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const getNewsDetailData = useSelector((state) => state?.news?.data);
  const loadingState = useSelector((state) => state?.news?.isLoading);

  useEffect(() => {
    dispatch(getNewsData());
  }, [dispatch]);

  const getSingleData = getNewsDetailData?.data?.data;

  const matchItem = getSingleData?.find((item) => item.slug === slug);

  return (
    <>
      <Helmet>
        <title>
          {`${matchItem ? matchItem?.title : ""}`} | Webtrans Logistics INC
        </title>
      </Helmet>
      <Header />
      {loadingState && (
        <div className="container my-5">
          <Skeleton count={50} />
        </div>
      )}
      {!loadingState && matchItem ? (
        <section className="service-details pb-5 mb-3">
          <div className="container">
            <div className="details-body">
              <div className="top-heaidng text-center mt-3 mt-lg-5 pt-3 mb-3 mt-lg-5">
                <h1 className="lg-heading captalize-text">
                  {matchItem?.title}
                </h1>
              </div>
              <div className="text-start mt-5">
                <p
                  className="text mb-4 pb-2"
                  dangerouslySetInnerHTML={{
                    __html: matchItem?.description_one,
                  }}
                ></p>
                <figure className="main-image mt-4 mb-5">
                  <img
                    src={matchItem?.news_image_url}
                    alt=""
                    className="the-img w-100"
                  />
                </figure>

                <p
                  className="text mb-4 pb-2"
                  dangerouslySetInnerHTML={{
                    __html: matchItem?.description_two,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="container py-3 py-md-5">
          <h2 className="text-center py-3 py-md-5 my-5">No Data Found</h2>
        </div>
      )}
      <Footer />
    </>
  );
};
export default NewsDetails;
