import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchGlossary } from "../../redux/glossary/glossarySlice";
import Skeleton from "react-loading-skeleton";
import WeightCalculator from "../../Components/WeightCalculator";
import WeightConversion from "../../Components/WeightConversion";
import CurencyConversion from "../../Components/CurencyConversion";
import StepBoxes from "../../Components/StepBoxes";
import { Helmet } from "react-helmet";
import { fetchConversions } from "../../redux/Conversions/conversionSlice";

const Tools = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector((state) => state?.glossary);
  const conversionsData = useSelector((state) => state?.conversions?.data);
  const conversionsLoading = useSelector(
    (state) => state?.conversions?.isLoading
  );
  useEffect(() => {
    dispatch(fetchGlossary());
    dispatch(fetchConversions());
  }, [dispatch]);

  const glossaryContent = data?.data?.slice().reverse();

  const [id, setId] = useState(3);
  const [ShowData, ChangeShowData] = useState(10);
  const [BtnText, ChangeBtnText] = useState("Load More");

  // set index for slicing glossary Data
  const [dataIndex, setDataIndex] = useState(10);

  // Load More Content
  const loadMore = (glossaryContent) => {
    setDataIndex(dataIndex + 10);
  };

  // Display Elements
  const showLoadedData = (glossaryContent, dataIndex) => {
    return glossaryContent?.slice(0, dataIndex)?.map((el, i) => (
      <li className="single-box" key={i}>
        <div className="box-name primary-text">
          <span className="md-text">{el?.title}</span>
        </div>
        <div className="box-text">
          <p
            className="text mb-0"
            dangerouslySetInnerHTML={{
              __html: el?.description,
            }}
          ></p>
        </div>
      </li>
    ));
  };

  let NameList = [
    {
      TheId: 1,
      TheName: "Incoterms",
    },
    {
      TheId: 2,
      TheName: "Converter/Tools",
    },
    {
      TheId: 3,
      TheName: "Glossary Of Terms",
    },
  ];

  let ToolsList = [
    {
      ListId: 1,
      ListData: glossaryContent,
    },
    {
      ListId: 2,
      ListData: glossaryContent,
    },
    {
      ListId: 3,
      ListData: glossaryContent,
    },
  ];
  const handleButtonClick = (buttonId) => {
    setId(buttonId);
  };
  let active_data = ToolsList.find((obj) => obj.ListId === id);

  // const MoreData = () => {
  //   if (ShowData >= active_data.ListData.length) {
  //   if (ShowData >= glossaryContent?.length) {
  //     ChangeShowData(ShowData - 10);
  //     ChangeBtnText("Show Less");
  //   } else {
  //     ChangeShowData(ShowData + 10);
  //     ChangeBtnText("Load More");
  //   }
  // };

  let option1 = "Buyer";
  let option2 = "Seller";
  let TableData = [
    {
      incoterms: "EXW",
      ecd: option1,
      ctp2: option1,
      uot: option1,
      lcp: option1,
      cca: option1,
      ucp: option1,
      lat: option1,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "FCA",
      ecd: option2,
      ctp2: option1,
      uot: option1,
      lcp: option1,
      cca: option1,
      ucp: option1,
      lat: option1,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "FAS",
      ecd: option2,
      ctp2: option2,
      uot: option1,
      lcp: option1,
      cca: option1,
      ucp: option1,
      lat: option1,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "FOB",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option1,
      ucp: option1,
      lat: option1,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "CFR",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option1,
      lat: option1,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "CIF",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option2,
      lat: option1,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "CPT",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option2,
      lat: option2,
      ctp: option1,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "CIP",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option2,
      lat: option2,
      ctp: option2,
      insurance: option1,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "DAT",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option2,
      lat: option2,
      ctp: option2,
      insurance: option2,
      icd: option1,
      ut: option1,
    },
    {
      incoterms: "DAP",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option2,
      lat: option2,
      ctp: option2,
      insurance: option2,
      icd: option2,
      ut: option1,
    },
    {
      incoterms: "DDP",
      ecd: option2,
      ctp2: option2,
      uot: option2,
      lcp: option2,
      cca: option2,
      ucp: option2,
      lat: option2,
      ctp: option2,
      insurance: option2,
      icd: option2,
      ut: option2,
    },
  ];

  // if(isLoading) {
  //     return '...Loading'
  // }

  return (
    <>
      <Helmet>
        <title>Tools | Webtrans Logistics INC</title>
      </Helmet>
      <Header />
      {/* Tools Section Start Here */}
      <section className="tools-section pb-5">
        <div className="container">
          <div className="tools-sections-start">
            <div className="top-heading text-center">
              <h1 className="lg-heading mt-5 animate__animated animate__bounceInLeft animate__delay-1s">
                Tools
              </h1>
            </div>
            <ul className="tools-list mt-4">
              {NameList.map((SingleName, index) => (
                <li className="list-items" key={index}>
                  <button
                    className={
                      index + 1 === id ? "tool-btn  active" : "tool-btn "
                    }
                    onClick={() => handleButtonClick(SingleName.TheId)}
                  >
                    {SingleName.TheName}
                  </button>
                </li>
              ))}
            </ul>
            {active_data.ListId === 3 && (
              <>
                <div className="col-xl-10 col-lg-11 mx-auto">
                  <ul className="box-list mt-4">
                    {isLoading &&
                      Array(glossaryContent?.length)
                        .fill(0)
                        .map((_, i) => (
                          <div key={i}>
                            <Skeleton count={5} />
                          </div>
                        ))}

                    {!isLoading && showLoadedData(glossaryContent, dataIndex)}
                  </ul>
                  <div className="text-center mt-4 pb-4">
                    <button className="btn" onClick={loadMore}>
                      {BtnText}
                    </button>
                  </div>
                </div>
              </>
            )}
            {active_data.ListId === 2 && (
              <>
                <div className="converter-tools-start">
                  <StepBoxes props={conversionsData?.data} />
                </div>
                <div className="weight-calculator">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6 pe-xl-0">
                      <div className="calcutlator-text animate__animated animate__bounceInRight animate__delay-1s">
                        <h2 className="md-heading">
                          {/* Dimensional Weight Calculator */}
                          {conversionsData?.data?.dimension_title}
                        </h2>
                        <div className="text-here mt-4">
                          <p className="text">
                            {conversionsData?.data?.dimension_content}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 ps-xl-4">
                      <WeightCalculator />
                    </div>
                  </div>
                </div>
                <div className="weight-cconversion">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-md-6 pe-lg-5 order-1 order-md-0 mt-5 mt-md-0 animate__animated animate__bounceInRight animate__delay-2s">
                      <WeightConversion />
                    </div>
                    <div className="col-lg-5 col-md-6 ps-lg-0 order-0 order-md-1 animate__animated animate__bounceInLeft animate__delay-1s">
                      <div className="convversion-text primary-text ">
                        <h2 className="md-heading">
                          {/* Weight Conversion Calculator */}
                          {conversionsData?.data?.weight_title}
                        </h2>
                        <div className="single-example mt-4">
                          <h6 className="xs-heading">Example</h6>
                          <p className="text">
                            {conversionsData?.data?.weight_content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="currency-convrsion-start py-5">
                  <div className="row pt-2  align-items-center">
                    <div className="col-lg-5 col-md-6 pe-lg-0">
                      <div className="currency-converion-text animate__animated animate__bounceInLeft animate__delay-1s">
                        <h2 className="md-heading">
                          {/* Currency Conversion Calculator */}
                          {conversionsData?.data?.currency_title}
                        </h2>
                        <div className="text-here">
                          {/* <h6 className="xs-heading mt-4">Example</h6> */}
                          <p>{conversionsData?.data?.currency_content}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 ps-lg-5">
                      <CurencyConversion />
                    </div>
                  </div>
                </div>
              </>
            )}
            {active_data.ListId === 1 && (
              <>
                <div className="incoterms-table-start">
                  <table className="incoterms-table">
                    <thead className="table-head">
                      <tr className="head-row">
                        <td className="normal-td td-head">Incoterm 2010</td>
                        <td className="normal-td td-head">
                          Export Customs Declaration
                        </td>
                        <td className="normal-td td-head">
                          Carriage to port of export
                        </td>
                        <td className="normal-td td-head">
                          Unloading of Truck In port of Export
                        </td>
                        <td className="normal-td td-head">
                          Loading Charges in port of export
                        </td>
                        <td className="normal-td td-head">
                          Carriage (Sea Freight /Air fright) To Port Of Import
                        </td>
                        <td className="normal-td td-head">
                          Unloading Charges in port of import
                        </td>
                        <td className="normal-td td-head">
                          Loading An Truck In port Of Import
                        </td>
                        <td className="normal-td td-head">
                          Carriage to Place of Destination
                        </td>
                        <td className="normal-td td-head">Insurance</td>
                        <td className="normal-td td-head">
                          Import Customs Dearance
                        </td>
                        <td className="normal-td td-head">Import Taxes</td>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {TableData.map((data, index) => (
                        <>
                          <tr key={index}>
                            <td className="normal-td td-head data-name">
                              {Object.values(data)[0]}
                            </td>

                            <td
                              className={
                                data.ecd === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.icd}
                            </td>
                            <td
                              className={
                                data.ctp2 === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.ctp2}
                            </td>
                            <td
                              className={
                                data.uot === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.uot}
                            </td>
                            <td
                              className={
                                data.lcp === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.lcp}
                            </td>
                            <td
                              className={
                                data.cca === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.cca}
                            </td>
                            <td
                              className={
                                data.ucp === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.ucp}
                            </td>
                            <td
                              className={
                                data.lat === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.lat}
                            </td>
                            <td
                              className={
                                data.ctp === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.ctp}
                            </td>
                            <td
                              className={
                                data.insurance === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.insurance}
                            </td>
                            <td
                              className={
                                data.icd === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.icd}
                            </td>
                            <td
                              className={
                                data.ut === "Buyer"
                                  ? "normal-td text"
                                  : "normal-td text seller"
                              }
                            >
                              {data.ut}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {/* Add more if statements for additional conditions */}
          </div>
        </div>
      </section>
      {/* Tools Section End Here */}
      {/* Footer Included Here */}
      <Footer />
      {/* Footer Included Here */}
    </>
  );
};
export default Tools;
