import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const submitContactForm = createAsyncThunk(
  "contact/submitForm",
  async (formData, thunkAPI) => {
    try {
      const newItem = new FormData();
      newItem.append("first_name", formData.firstName);
      newItem.append("last_name", formData.lastName);
      newItem.append("email", formData.email);
      newItem.append("contact", formData.contact);
      newItem.append("message", formData.message);

      // Make your API request here
      const response = await fetch(
        "https://webtrans.developer-ourbase-camp.com/api/office-address/send-contact-query",
        {
          method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          body: newItem,
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    submitting: false,
    submitted: false,
    error: null,
    contactQuery: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.submitting = true;
        state.submitted = false;
        state.error = null;
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.submitted = true;
        state.error = null;
        state.contactQuery = action.payload;
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.submitting = false;
        state.submitted = false;
        state.error = action.payload;
      });
  },
});

export default contactSlice.reducer;
