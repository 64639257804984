import React, { useEffect } from "react";
import { useState } from "react";

const WeightCalculator = () => {
  const [pieces, setPieces] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [measurment, setMeasurment] = useState("cm");
  const [weightType, setWeightType] = useState("lbs");
  const [airResult, setAirResult] = useState("");
  const [oceanResult, setOceanResult] = useState("");
  const [validation, setValidation] = useState(false);
  const [invalidEntry, setInvalidEntry] = useState(false);

  const crunch = (e) => {
    e.preventDefault();

    // check for blank fields
    if (
      pieces.length === 0 ||
      length.length === 0 ||
      width.length === 0 ||
      height.length === 0 ||
      weight.length === 0
    ) {
      setValidation(true);
    } else {
      setValidation(false);
    }

    // Check that it is a valid number
    // const isValidNumber = (field) => {
    //   const number = parseFloat(field);
    //   return !isNaN(number);
    // };

    // check for invalid entry
    // if (!isValidNumber(length)) {
    //   setInvalidEntry(true)
    // }
    // if (!isValidNumber(width)) {
    //   setInvalidEntry(true)
    // }
    // if (!isValidNumber(height)) {
    //   setInvalidEntry(true)
    // }
    // if (!isValidNumber(weight)) {
    //   setInvalidEntry(true)
    // }

    const isValidNumber = (field) => {
      const number = parseFloat(field);
      return !isNaN(number);
    };

    // Assuming you have length, width, height, and weight as your input fields
    const areAllFieldsValid =
      isValidNumber(length) &&
      isValidNumber(width) &&
      isValidNumber(height) &&
      isValidNumber(weight) &&
      isValidNumber(pieces);

    setInvalidEntry(!areAllFieldsValid);

    // Perform calculations
    if (!validation) {
      const dims = length * width * height;
      const piecesValue = pieces;
      const weightValue = weight;

      let airDims, airWeight, oceanDims, oceanWeight, oceanResultText;

      if (measurment === "inches") {
        airDims = (dims / 366) * piecesValue;
        oceanDims = (dims / 1728 / 35.32) * piecesValue;
      } else {
        airDims = (dims / 6000) * piecesValue;
        oceanDims = (dims / 1000000) * piecesValue;
      }

      if (weightType === "lbs") {
        airWeight = (weightValue / 2.2046) * piecesValue;
        oceanWeight = (weightValue / 2.2046 / 1000) * piecesValue;
      } else {
        airWeight = weightValue * piecesValue;
        oceanWeight = (weightValue / 1000) * piecesValue;
      }

      const air = Math.round(Math.max(airDims, airWeight));
      const ocean = Math.max(oceanDims, oceanWeight);

      if (oceanDims >= oceanWeight) {
        oceanResultText = `${rounder(oceanDims)} m3`;
      } else {
        oceanResultText = `${rounder(oceanWeight)} mt`;
      }

      setAirResult(`${air} kgs`);
      setOceanResult(oceanResultText);
    }
  };

  // Ocean weight rounding
  const rounder = (n) => {
    const multBy1000 = n * 1000;
    const extra0 = Math.round(multBy1000);
    const pen = new String(extra0);
    const pen1 = pen.substring(0, pen.length - 3);
    const pen2 = pen.substring(pen.length, pen.length - 3);
    return `${pen1}.${pen2}`;
  };

  const handleReset = (e) => {
    e.preventDefault();
    setPieces("");
    setLength("");
    setWidth("");
    setWeight("");
    setHeight("");
    setHeight("");
    setAirResult("");
    setOceanResult("");
  };

  return (
    <div className="weight-calculator-form animate__animated animate__bounceInLeft animate__delay-1s">
      {/* <h1>Dimesional Weight : {calculatorResult}</h1> */}
      <form className="custom-from" method="POST">
        <div className="row">
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Total Pieces
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Total Pieces"
                value={pieces}
                onChange={(e) => setPieces(e.target.value)}
              />
            </div>
            {validation && pieces.length <= 0 ? (
              <p className="err__message">"Please fill out this field"</p>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Length
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Length"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
            </div>
            {validation && length.length <= 0 ? (
              <p className="err__message">"Please fill out this field"</p>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Width
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
              />
            </div>
            {validation && width.length <= 0 ? (
              <p className="err__message">"Please fill out this field"</p>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Height
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </div>
            {validation && height.length <= 0 ? (
              <p className="err__message">"Please fill out this field"</p>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Actual Weight (lbs)
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Actual Weight (lbs)"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </div>
            {validation && weight.length <= 0 ? (
              <p className="err__message">"Please fill out this field"</p>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label d-block">
                Select measurment type
              </label>
              <div className="form-flex d-flex gap-3 gap-lg-4 mt-3 pt-1">
                <div className="single-check">
                  <input
                    type="radio"
                    id="centimeter"
                    className="custom-radio cm__radio"
                    name="size"
                    value={"cm"}
                    checked={measurment === "cm"}
                    onChange={(e) => setMeasurment(e.target.value)}
                  />
                  <label htmlFor="centimeter" className="custom-radio-label">
                    Centimeters
                  </label>
                </div>
                <div className="single-check">
                  <input
                    type="radio"
                    id="inches"
                    className="custom-radio"
                    name="size"
                    value={"inches"}
                    checked={measurment === "inches"}
                    onChange={(e) => setMeasurment(e.target.value)}
                  />
                  <label htmlFor="inches" className="custom-radio-label">
                    Inches
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label d-block">
                Select Weight type
              </label>
              <div className="form-flex d-flex gap-3 gap-lg-4 mt-3 pt-1">
                <div className="single-check">
                  <input
                    type="radio"
                    id="kg"
                    className="custom-radio"
                    name="weight"
                    value={"kg"}
                    checked={weightType === "kg"}
                    onChange={(e) => setWeightType(e.target.value)}
                  />
                  <label htmlFor="kg" className="custom-radio-label">
                    Kilograms
                  </label>
                </div>
                <div className="single-check">
                  <input
                    type="radio"
                    id="pounds"
                    className="custom-radio"
                    name="weight"
                    value={"lbs"}
                    checked={weightType === "lbs"}
                    onChange={(e) => setWeightType(e.target.value)}
                  />
                  <label htmlFor="pounds" className="custom-radio-label">
                    Pounds
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-3 col-md-6 mt-4">
            <label htmlFor="" className="opacity-0">
              .
            </label>
            <button className="primar-btn btn w-100" onClick={crunch}>
              Calculate
            </button>
          </div>
          {/* <p>Air Result: {airResult}</p>
        <p>Ocean Result: {oceanResult}</p> */}
          <div className="col-lg-3 col-md-6 mt-4">
            <label htmlFor="" className="opacity-0">
              .
            </label>
            <button className="primar-btn btn w-100" onClick={handleReset}>
              Reset
            </button>
          </div>
          <div className="col-lg-3 col-md-6 mt-4">
            <label htmlFor="" className="custom-label d-block">
              Dimensional weight
            </label>
            {/* <button className=""></button> */}
            <input
              type="text"
              name=""
              id=""
              disabled
              value={airResult}
              className=" form-control transparent-btn w-100 px-0"
              placeholder="Dimensional weight"
            />
          </div>
          <div className="col-lg-3 col-md-6 mt-4">
            <label htmlFor="" className="custom-label d-block">
              Density
            </label>
            {/* <button className="btn transparent-btn w-100 px-0">Density</button> */}
            <input
              type="text"
              name=""
              id=""
              className=" form-control transparent-btn w-100 px-0"
              placeholder="Density"
              value={oceanResult}
              disabled
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WeightCalculator;
