import { useEffect, useState } from "react";
import { BannerImg, BannerImg1 } from "../../Constant/Index";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const HomeBanner = ({ data, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Skeleton count={30} />
      ) : (
        <section className="banner-section">
          <div className="container-fluid px-0">
            <div className="banner-slider-start">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                {data?.banner?.length > 0 && (
                  <div className="carousel-indicators">
                    {data.banner.map((slide, index) => (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current={index === 0 ? "true" : "false"}
                        aria-label={`Slide ${index + 1}`}
                      ></button>
                    ))}
                  </div>
                )}

                <div className="carousel-inner">
                  {data?.banner &&
                    data?.banner?.length > 0 &&
                    data?.banner?.map((item, i) => (
                      <div
                        className={`carousel-item  ${i === 0 ? "active" : ""}`}
                        key={i}
                      >
                        {item?.banner_image_url ? (
                          <div className="loaded">
                            <img
                              src={item?.banner_image_url}
                              className="d-block w-100 slider-img"
                              alt="..."
                              loading="lazy"
                            />
                          </div>
                        ) : (
                          <div className="blur__img">
                            <img
                              src={BannerImg}
                              className="d-block w-100 slider-img"
                              alt="..."
                              loading="lazy"
                            />
                          </div>
                        )}
                        <div className="carousel-caption d-md-block w-100 p-0 custom-caption">
                          <div className="row mx-0 h-100">
                            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-8 ps-0 pe-xl-4 pe-xxl-5 h-100 position-relative custom-width">
                              <div className="blue-section primary-text">
                                <h1 className="main-heading captalize-text animate__animated animate__backInLeft">
                                  {item?.title?.length >= 35
                                    ? `${item?.title?.substring(0, 35)}...`
                                    : item?.title}
                                </h1>
                                <p
                                  className="text animate__animated animate__backInLeft m-0"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.content,
                                  }}
                                ></p>
                                <Link
                                  to="/contact-us"
                                  className="btn main-text primary-bg animate__animated animate__bounceIn"
                                >
                                  Get Started
                                </Link>
                                <figure className="man-image position-absolute bottom-0 animate__animated animate__bounceInLeft  animate__delay-1s">
                                  <img src={BannerImg1} alt="" />
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HomeBanner;
