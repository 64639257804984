import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { getServiceData } from "../../redux/Services/serviceSlice";
import { Helmet } from "react-helmet";

const ServiceDetials = () => {
  const dispatch = useDispatch();
  const allServices = useSelector((state) => state?.services?.data);
  const isLoading = useSelector((state) => state?.services?.isLoading);
  const isError = useSelector((state) => state?.services?.isError);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getServiceData());
  }, [dispatch]);

  const singleService = allServices?.data?.data;


  const currentService = singleService?.find((item) => item?.slug === slug);

  return (
    <>
      <Helmet>
        <title>
          {`${currentService ? currentService?.title : ""}`} | Webtrans
          Logistics INC
        </title>
      </Helmet>
      <Header />
      {isLoading && (
        <div className="container my-5">
          <Skeleton count={100} />
        </div>
      )}
      {!isLoading && currentService ? (
        <section className="service-details">
          <div className="container">
            <div className="details-body">
              <div className="top-heaidng text-center mt-3 mt-lg-5 pt-3 mb-3 mt-lg-5">
                <h1 className="lg-heading captalize-text">
                  {currentService?.title}
                </h1>
              </div>
              <div className="text-start">
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: currentService?.description_one,
                  }}
                ></p>
                <figure className="main-image mt-4 mb-5">
                  <img
                    src={currentService?.service_image_url}
                    alt=""
                    className="the-img w-100"
                  />
                </figure>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: currentService?.description_two,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="container py-3 py-md-5">
          <h2 className="text-center py-3 py-md-5 my-5">No Data Found</h2>
        </div>
      )}
      <Footer />
    </>
  );
};
export default ServiceDetials;
