import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getSiteSettings = createAsyncThunk("site/settings", async () => {
  try {
    const response = await fetch(
      "https://webtrans.developer-ourbase-camp.com/api/home/site-settings"
    );
    const fetchedData = await response.json();
    return fetchedData;
  } catch (error) {
    throw error;
  }
});

export const siteSettingsSlice = createSlice({
  name: "settings",
  initialState: {
    isLoading: false,
    isError: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSiteSettings.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getSiteSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getSiteSettings.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = true;
      });
  },
});

export default siteSettingsSlice.reducer;

