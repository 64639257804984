import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./Home/homeSlice";
import contactUsReducer from "./Contact/contactSlice";
import serviceReducer from "./Services/serviceSlice";
import newsReducer from "./News/newsSlice";
import postContactReducer from "./Contact/contactService";
import glossaryReducer from "./glossary/glossarySlice";
import currencyExchangeReducer from "./CurrencyExchange/currencyExchange";
import pageReducer from "./PagesData/pagesSlice";
import settingsReducer from "./SiteSettings/siteSettingsSlice";
import conversionReducer from "./Conversions/conversionSlice";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    contactUs: contactUsReducer,
    services: serviceReducer,
    news: newsReducer,
    contact: postContactReducer,
    glossary: glossaryReducer,
    currencyExchange: currencyExchangeReducer,
    pagesData: pageReducer,
    settings: settingsReducer,
    conversions: conversionReducer,
  },
});
