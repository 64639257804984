import React, { useEffect, useState } from "react";

const WeightConversion = () => {
  const [fromLbs, setFromLbs] = useState("");
  const [toKg, setToKg] = useState("");
  const [fromKg, setFromKg] = useState("");
  const [tolbs, setToLbs] = useState("");

  useEffect(() => {
    let resultToKg = (fromLbs * 0.45359237).toFixed(2);
    setToKg(resultToKg);

    let resultToLbs = (fromKg * 2.20462).toFixed(2);
    setToLbs(resultToLbs);
  }, [fromLbs, fromKg]);

  const handleReset = (e) => {
    e.preventDefault();

    if (fromLbs || fromKg) {
      setFromLbs("");
      setToKg("");
      setFromKg("");
      setToLbs("");
    }
  };

  return (
    <div className="alternate-form me-lg-2">
      <form className="custom-from py-4">
        <div className="row py-lg-3">
          <h6 className="xs-heading mb-0">From Pounds to Kilograms</h6>
          <div className="col-lg-6 col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Lbs
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Lbs"
                value={fromLbs}
                onChange={(e) => setFromLbs(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Kgs
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Kgs"
                disabled
                value={toKg}
                onChange={(e) => setToKg(e.target.value)}
              />
            </div>
          </div>
          <h6 className="xs-heading mt-5 mb-0">From Kilograms to Pounds</h6>
          <div className="col-lg-6 col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Kgs
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Kgs"
                value={fromKg}
                onChange={(e) => setFromKg(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Lbs
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                disabled
                placeholder="Lbs"
                value={tolbs}
                onChange={(e) => setToLbs(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <button className="btn w-100" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WeightConversion;
