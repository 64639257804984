import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../Constant/Index";
const NewsSec = ({ data }) => {
  return (
    <section className="news-section">
      <div className="container">
        <div className="news-start-here">
          <div className="top-headings text-center  mx-auto animate__animated animate__backInLeft animate__delay-2s">
            <span className="small-heading">
              {data?.["news-page"]?.page_title}
            </span>
            <h3 className="md-heading">{data?.["news-page"]?.news_title}</h3>
            <p
              className="text"
              dangerouslySetInnerHTML={{
                __html: data?.["news-page"]?.content,
              }}
            ></p>
          </div>
          <div className="news-list">
            <div className="row">
              {data?.news &&
                data?.news?.length &&
                data?.news?.map((single) => (
                  <div
                    className="col-lg-3 col-md-6 col-sm-6 mt-3"
                    key={single?.id}
                  >
                    <div className="single-news animate__animated animate__backInLeft animate__delay-2s">
                      <figure className="news-image">
                        <img
                          src={single?.news_image_url}
                          alt=""
                          className="the-image w-100"
                        />
                      </figure>
                      <div className="news-body">
                        <h5 className="xs-heading crop-1-lines">
                          {single?.title}
                        </h5>
                        <p
                          className="text "
                          dangerouslySetInnerHTML={{
                            __html:
                              single?.description_one.length > 90
                                ? `${single?.description_one.substring(
                                    0,
                                    90
                                  )}...`
                                : single?.description_one,
                          }}
                        ></p>
                        <span className="date">
                          {new Date(single?.created_at).toLocaleDateString()}
                        </span>
                        {/* <span className="date">{formattedDates}</span> */}
                        <Link
                          to={`/news-details/${single?.slug}`}
                          className="news-link"
                        >
                          <img src={ArrowRightIcon} alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsSec;
