import { Step1Icon, Step2Icon, Step3Icon } from "../Constant/Index";

const StepBoxes = ({props}) => {
  return (
    <div className="step-boxes">
      <p className="text text-center">
        Use our tools for easy methods of converting weight, currency and volume
        for all your shipments,
      </p>
      <div className="steps" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-step animate__animated animate__bounceInUp animate__delay-1s">
              <figure className="icon">
                <img src={Step1Icon} alt="" className="the-icon" />
              </figure>
              <div className="text-here">
                <h4 className="sm-heading">Step 1</h4>
                <p className="md-text">
                  {props?.step_one}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-step animate__animated animate__bounceInUp animate__delay-1s">
              <figure className="icon">
                <img src={Step2Icon} alt="" className="the-icon" />
              </figure>
              <div className="text-here">
                <h4 className="sm-heading">Step 2</h4>
                <p className="md-text">
                {props?.step_two}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-step  animate__animated animate__bounceInUp animate__delay-1s">
              <figure className="icon">
                <img src={Step3Icon} alt="" className="the-icon" />
              </figure>
              <div className="text-here">
                <h4 className="sm-heading">Step 3</h4>
                <p className="md-text">
                {props?.step_three}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepBoxes;
