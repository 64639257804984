import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getPageData = createAsyncThunk("data/pages", async (slug) => {
  try {
    const response = await fetch(
      `https://webtrans.developer-ourbase-camp.com/api/pages?slug=${slug}`
    );
    const data = response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const PagesSlice = createSlice({
  name: "pagesData",
  initialState: {
    isLoading: false,
    isError: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPageData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getPageData.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = true;
      });
  },
});

export default PagesSlice.reducer;
