// apiSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchConversions = createAsyncThunk(
  "data/conversions",
  async () => {
    const response = await fetch(
      `https://webtrans.developer-ourbase-camp.com/api/conversion`
    );
    const fetchedData = await response.json();
    return fetchedData;
  }
);

const conversionSlice = createSlice({
  name: "conversions",
  initialState: {
    data: [],
    isLoading: false,
    isError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchConversions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchConversions.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = true;
        state.isError = action.error.message;
      });
  },
});

export default conversionSlice.reducer;