import React, { useEffect } from "react";
import { Logo2, SendIcon } from "../Constant/Index";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSiteSettings } from "../redux/SiteSettings/siteSettingsSlice";
const Footer = () => {
  const dispatch = useDispatch();
  const siteSettings = useSelector((state) => state?.settings?.data);

  useEffect(() => {
    dispatch(getSiteSettings());
  }, [dispatch]);

  const data = siteSettings?.data?.["siteSetting"]?.[0];

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-start">
            <div className="row pb-3">
              <div className="col-xl-4 col-lg-4 mt-5 mt-xl-0">
                <div className="footer-single-column  primary-text">
                  <div className="footer-text">
                    <figure className="footer-logo">
                      <Link to="">
                        <img src={Logo2} alt="" className="the-img" />
                      </Link>
                    </figure>
                    <p className="text">{data?.footer_sentence}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-8 mt-5 mt-xl-0">
                <div className="footer-single-column">
                  <div className="quick-link-flex d-flex justify-content-between">
                    <div className="single-links-columns  primary-text">
                      <h6 className="xs-heading small">Quick Links</h6>
                      <ul className="footer-links">
                        <li className="list-items">
                          <Link className="single-link" to="/">
                            Home
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link className="single-link" to="/aboutus">
                            About Us
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link className="single-link" to="/services">
                            Services
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link className="single-link" to="/tools">
                            Tools
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link className="single-link" to="/news">
                            News
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link
                            className="single-link"
                            to="http://webtransusa.com/account/login.asp?p_url=/member/order_list.asp&p_query="
                            target="_blank"
                          >
                            Online Tools
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link className="single-link" to="/contact-us">
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="single-links-columns  primary-text">
                      <h6 className="xs-heading small">Legal</h6>
                      <ul className="footer-links">
                        <li className="list-items">
                          <Link className="single-link" to="/terms-conditions">
                            Terms and Conditions
                          </Link>
                        </li>
                        <li className="list-items">
                          <Link
                            className="single-link"
                            to="/shipping-insurance"
                          >
                            Shipper’s Insurance
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 mt-5 mt-xl-0">
                <div className="footer-single-column primary-text">
                  <div className="contact-footer">
                    <h6 className="xs-heading small">Contact us</h6>
                    <ul className="contact-lis">
                      <li className="contact-item">
                        <i className="icon fa fa-map-marker"></i>
                        <span className="contact-info">{data?.address}</span>
                      </li>
                      <li className="contact-item">
                        <i className="icon fa fa-phone"></i>
                        <Link
                          className="contact-info"
                          to={`tel:${data?.contact_phone}`}
                        >
                          {data?.contact_phone}
                        </Link>
                      </li>
                      <li className="contact-item">
                        <i className="icon fa fa-envelope"></i>
                        <Link
                          className="contact-info"
                          to={`mailto:${data?.contact_email}`}
                        >
                          {data?.contact_email}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copy-write d-flex align-items-center justify-content-between align-items-start align-items-lg-center mt-4 ">
              <p className="text">{data?.copyright}</p>
              <ul className="right-social d-flex list-style-none gap-2">
                {data?.twitter && (
                  <li>
                    <Link to={data?.twitter}>
                      {/* <i className="fa fa-twitter"></i> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                      </svg>
                    </Link>
                  </li>
                )}
                {data?.facebook && (
                  <li>
                    <Link to={data?.facebook}>
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                )}
                {data?.pinterest && (
                  <li>
                    <Link to={data?.pinterest}>
                      <i className="fa fa-pinterest"></i>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
