 import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getNewsData = createAsyncThunk("data/news", async (page, itemsPerPage) => {
  try {
    const response = await fetch(
      `https://webtrans.developer-ourbase-camp.com/api/news?page=${page}&per_page=${itemsPerPage}`
    );
    const fetchedData = await response.json();
    return fetchedData;
  } catch (error) {
    throw error;
  }
});

export const NewsSlice = createSlice({
  name: "news",
  initialState: {
    isLoading: false,
    isError: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewsData.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getNewsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getNewsData.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = true;
      });
  },
});

export default NewsSlice.reducer;
