import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getServiceData = createAsyncThunk("data/services", async () => {
  try {
    const response = await fetch(
      "https://webtrans.developer-ourbase-camp.com/api/services"
    );
    const fetchedData = await response.json();
    return fetchedData;
  } catch (error) {
    throw error;
  }
});

export const serviceSlice = createSlice({
  name: "services",
  initialState: {
    isLoading: false,
    isError: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServiceData.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getServiceData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getServiceData.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = true;
      });
  },
});

export default serviceSlice.reducer;

