import React, { useEffect, useRef } from "react";
import { MapImg1 } from "../../Constant/Index";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import DataLoader from "../../Components/DataLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContactData,
  getContactData,
  postContactData,
} from "../../redux/Contact/contactSlice";
import ContactForm from "../../Components/ContactForm";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const dispatch = useDispatch();
  const contactDetails = useSelector(getContactData);
  const isDataLoading = useSelector((state) => state?.contactUs?.isLoading);

  useEffect(() => {
    dispatch(fetchContactData());
  }, [dispatch]);

  const { data } = contactDetails;
  const addressData = data?.["Office Address "];

  if (isDataLoading) {
    return <DataLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Contact Us | Webtrans Logistics INC</title>
      </Helmet>
      <Header />
      {/* Contact Section start Here */}
      <section className="index-contact-section contact-page">
        <div className="container">
          <div className="contact-start">
            <div className="top-heading main text-center">
              <h2 className="lg-heading mb-5 mt-5">Contact Us</h2>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-5">
                <h5 className="sm-heading captalize-text">
                  our contact details
                </h5>
                <div className="contact-details">
                  <div className="top-text">
                    {addressData?.map((branch, index) => (
                      <div className="sinlge-branch mb-4 pb-2" key={index}>
                        <div className="current-hq">
                          <h3 className="md-text">
                            {branch?.location_name} <br />
                            <span className="ps-0">
                              ({branch?.location_type})
                            </span>
                          </h3>
                        </div>
                        <ul className="contact-lis">
                          <li className="contact-item">
                            <i className="icon fa fa-map-marker"></i>
                            <span className="contact-info">
                              276{branch?.address}
                            </span>
                          </li>
                          <li className="contact-item">
                            <i className="icon fa fa-envelope"></i>
                            <a
                              className="contact-info"
                              href={"mailto: " + branch?.email}
                            >
                              Email: {branch?.email}
                            </a>
                          </li>
                          <li className="contact-item">
                            <i className="icon fa fa-phone"></i>
                            <a
                              className="contact-info"
                              href={"tel:" + branch?.phone}
                            >
                              Tel: {branch?.phone}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-7 col-lg-7 col-md-7 mt-lg-0 mt-4 d-flex flex-column justify-content-start">
                <div className="contact-index-form">
                  <ContactForm />
                  <div className="map-here mt-4">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6999584.908037069!2d-105.36941405478338!3d31.060611276577166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864070360b823249%3A0x16eb1c8f1808de3c!2sTexas%2C%20USA!5e0!3m2!1sen!2s!4v1688547942513!5m2!1sen!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <img src={MapImg1} alt="" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section End Here */}
      {/* Footer Included Here */}
      <Footer />
      {/* Footer Included Here */}
    </>
  );
};
export default ContactUs;
