import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyExchangeData } from "../redux/CurrencyExchange/currencyExchange";
import { useEffect } from "react";

const CurencyConversion = () => {
  const dispatch = useDispatch();
  const convertedAmount = useSelector(
    (state) => state?.currencyExchange?.convertedAmount
  );
  const isLoading = useSelector((state) => state?.currencyExchange?.loading);

  const result = convertedAmount?.data;
  // console.log(result, 'result')


  const [amount, setAmount] = useState("");
  const [validation, setValidation] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [fromCurrency, setFromCurrency] = useState('Select Currency');
  const [toCurrency, setToCurrency] = useState('Select Currency');

    // Check for Validation
    const handleValidation = (e) => {
      e.preventDefault();
      if (amount.length === 0) {
        setValidation(true);
      } else {
        setValidation(false);
      }
    };

  const handleExchange = (e) => {
    dispatch(
      getCurrencyExchangeData({ from: fromCurrency, to: toCurrency, amount })
    );
    handleValidation(e)
  };

  // Get Countries List
  const fetchCountriesList = async () => {
    try {
      const countriesList = await fetch(
        "https://webtrans.developer-ourbase-camp.com/api/currency/country"
      );
      const response = await countriesList.json();
      setCountriesList(response);
    } catch (error) {
      console.error("Error Fetching Data", error);
    }
  };

  useEffect(() => {
    fetchCountriesList();
  }, []);

  // console.log(countriesList?.data?.[0]?.currency_code, 'converted')


  return (
    <div className="coversion-form animate__animated animate__bounceInRight animate__delay-1s">
      <form className="custom-from">
        <div className="row">
          <div className="col-lg-6 col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                From
              </label>
              <select
                className="form-control"
                name=""
                id=""
                value={fromCurrency}
                onChange={(e) => setFromCurrency(e.target.value)}
              >
                <option value={'select curency'}>Select Curency</option>
                {countriesList?.data?.map((option) => (
                  <option value={option?.currency_code} key={option?.id}>
                    {option?.country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                To
              </label>
              <select
                className="form-control"
                name=""
                id=""
                value={toCurrency}
                onChange={(e) => setToCurrency(e.target.value)}
              >
                <option value={'select curency'}>Select Curency</option>
                {countriesList?.data?.map((option) => (
                  <option value={option?.currency_code} key={option?.id}>
                    {option?.country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="1"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              {validation && amount.length <= 0 ? (
                <p className="err__message">Please Enter the Amount</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Converted Amount
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder=""
                value={result ? result?.toFixed(2) : 0}
                disabled
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-4">
            <span
              className="btn transparent-btn w-100 px-0"
              onClick={handleExchange}
            >
              {/* Convert */}
              {isLoading === "loading" ? "...Converting" : "Convert"}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CurencyConversion;
