import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getServiceData } from "../../redux/Services/serviceSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet";

const Services = () => {
  const dispatch = useDispatch();
  const servicesData = useSelector((state) => state?.services?.data);
  const loadingState = useSelector((state) => state?.services?.isLoading);

  // const { data } = servicesData;
  // const allServices = data?.["Services"];
  const allServices = servicesData?.data?.data;

  useEffect(() => {
    dispatch(getServiceData());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Services | Webtrans Logistics INC</title>
      </Helmet>
      <Header />
      <section className="services-section service-page">
        <div className="container">
          <div className="services-list">
            <div className="top-heading text-center mb-3">
              <h2 className="lg-heading animate__animated animate__bounceInLeft animate__delay-1s">
                Services
              </h2>
            </div>
            <div className="row align-items-center">
              {loadingState &&
                Array(6)
                  .fill(0)
                  .map((_, i) => (
                    <div className="col-lg-4" key={i}>
                      <Skeleton key={i} height={10} count={5} />
                    </div>
                  ))}
              {!loadingState &&
                allServices &&
                allServices?.length > 0 &&
                allServices?.map((service) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 mt-5 px-xl-3"
                    key={service?.id}
                  >
                    <div className="single-service animate__animated animate__bounceInDown  animate__delay-2s">
                      <h4 className="sm-heading">
                        {service?.title.length > 22
                          ? `${service?.title.substring(0, 22)}...`
                          : service?.title}
                      </h4>
                      <div className="the-text">
                        <p className="text crop-2-lines">
                          {/* {service?.description_one} */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: service?.description_one?.substring(
                                0,
                                100
                              ),
                            }}
                          ></span>
                          <Link
                            to={`/service-detail/${service?.slug}`}
                            className="read-more"
                          >
                            Read More
                          </Link>
                        </p>
                      </div>
                      <figure className="serivce-img">
                        <img
                          src={service?.service_image_url}
                          alt=""
                          className="the-img w-100"
                        />
                      </figure>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Services;
