import ContactForm from "../../Components/ContactForm";
import { MapImg2 } from "../../Constant/Index";

const ContactSec = ({ data }) => {
  const address = data?.address;
  return (
    <section className="index-contact-section">
      <div className="container">
        <div className="contact-start">
          <div className="row">
            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-5">
              <div className="contact-details animate__animated animate__backInLeft animate__delay-2s">
                <div className="top-text ">
                  <h5 className="sm-heading captalize-text">
                    our contact details
                  </h5>
                  <div className="current-hq">
                    <div className="md-text">
                      {address?.[0]?.location_name} <span>(Headquarter)</span>
                    </div>
                  </div>
                  <ul className="contact-lis">
                    <li className="contact-item">
                      <i className="icon fa fa-map-marker"></i>
                      <span className="contact-info">{address?.[0]?.address}</span>
                    </li>
                    <li className="contact-item">
                      <i className="icon fa fa-envelope"></i>
                      <a
                        className="contact-info"
                        href={`mailto:${address?.[0]?.email}`}
                      >
                        Email: {address?.[0]?.email}
                      </a>
                    </li>
                    <li className="contact-item">
                      <i className="icon fa fa-phone"></i>
                      <a
                        className="contact-info"
                        href={`tel:${address?.[0]?.phone}`}
                      >
                        Tel: {address?.[0]?.phone}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="map-here mt-4">
                  <img src={MapImg2} alt="" width="100%" />
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-7 col-lg-7 col-md-7 mt-lg-0 mt-4 d-flex flex-column justify-content-center">
              <div className="contact-index-form animate__animated animate__backInRight animate__delay-2s">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSec;
