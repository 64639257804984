import { useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm } from "../redux/Contact/contactService";
import { useEffect } from "react";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(3, "First Name must be at least 3 characters")
    .max(15, "First Name must be at most 15 characters")
    .required("First Name is Required!"),
  lastName: yup
    .string()
    .min(3, "First Name must be at least 3 characters")
    .max(15, "First Name must be at most 15 characters")
    .required("Last Name is Required!"),
  email: yup.string().email().required("Email Address is required!"),
  contact: yup
    .string()
    .matches(/^\d{10,12}$/, "Must be valid number between 10 and 12 digits")
    .typeError("Contact Number is Required"),
  message: yup.string().required("Please Input Your Message!"),
});

const ContactForm = () => {
  const dispatch = useDispatch();
  const submittedData = useSelector((state) => state?.contact?.contactQuery);
  const isSubmitted = useSelector((state) => state?.contact?.submitted);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      dispatch(submitContactForm(data));

      toast.success("Your request has been submitted successfully");
    } catch (error) {
      toast.error("Error Sending Form Request");
    }
  };

  useEffect(() => {
    if (submittedData?.statusCode === 200) {
      reset({
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        message: "",
      });
    }
  }, [isSubmitted]);

  const formRef = useRef();
  return (
    <>
      <form
        className="custom-from"
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <h5 className="sm-heading mb-1">Send Message without any hesitation</h5>
        <div className="row">
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="First Name"
                {...register("firstName")}
              />
              <p className="error__msg m-0 text-danger">
                {errors?.firstName?.message}
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Last Name"
                {...register("lastName")}
              />
              <p className="error__msg m-0 text-danger">
                {errors?.lastName?.message}
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                name=""
                id=""
                placeholder="Email Address"
                {...register("email")}
              />
              <p className="error__msg m-0 text-danger">
                {errors?.email?.message}
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Contact Number
              </label>
              <input
                type="text"
                className="form-control"
                name=""
                id=""
                placeholder="Contact Number"
                {...register("contact")}
              />
              <p className="error__msg m-0 text-danger">
                {errors?.contact?.message}
              </p>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="" className="custom-label">
                Message:
              </label>
              <textarea
                className="form-control form-md-control"
                name=""
                id=""
                placeholder="Message"
                {...register("message")}
              ></textarea>
              <p className="error__msg m-0 text-danger">
                {errors?.message?.message}
              </p>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <button className="btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
