import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Logo, BarsIcon } from "../Constant/Index";
import { useDispatch, useSelector } from "react-redux";
import { getSiteSettings } from "../redux/SiteSettings/siteSettingsSlice";

const Header = () => {
  const dispatch = useDispatch();
  const siteSettings = useSelector((state) => state?.settings?.data);

  useEffect(() => {
    dispatch(getSiteSettings());
  }, [dispatch]);

  const data = siteSettings?.data?.["siteSetting"]?.[0];

  const [mobileNav, SetmobileNav] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  function toggleNav() {
    SetmobileNav((prevState) => !prevState);
  }

  return (
    <>
      {loading ? (
        <>
          <div className="preloader">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
            </svg>
          </div>
        </>
      ) : (
        <header className="header">
          <div className="header_start">
            <div className="top-bar">
              <div className="container">
                <div className="flex-bar">
                  <ul className="left-social">
                    {data?.twitter && (
                      <li>
                        <Link to={data?.twitter}>
                          {/* <i className="fa fa-twitter"></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                          </svg>
                        </Link>
                      </li>
                    )}
                    {data?.facebook && (
                      <li>
                        <Link to={data?.facebook}>
                          <i className="fa fa-facebook"></i>
                        </Link>
                      </li>
                    )}
                    {data?.pinterest && (
                      <li>
                        <Link to={data?.pinterest}>
                          <i className="fa fa-pinterest"></i>
                        </Link>
                      </li>
                    )}
                  </ul>
                  <ul className="get-contact">
                    <li>
                      <a href={`mailto:${data?.contact_email}`}>
                        <i className="fa fa-envelope"></i>
                        {data?.contact_email}
                      </a>
                    </li>
                    <li>
                      <a href={`tel:${data?.contact_phone}`}>
                        <i className="fa fa-phone"></i>
                        {data?.contact_phone}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="main-flex-header">
              <div className="container">
                <div className="main-header">
                  <figure className="logo">
                    <Link to="/">
                      <img src={Logo} alt="" />
                    </Link>
                  </figure>
                  <div className="navbar_links">
                    <ul className="normal_links">
                      <li className="link-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="link-item">
                        <Link to="/aboutus">About Us</Link>
                      </li>
                      <li className="link-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="link-item">
                        <Link to="/tools">Tools</Link>
                      </li>
                      <li className="link-item">
                        <Link to="/news">News</Link>
                      </li>
                      <li className="link-item">
                        <Link
                          to="http://webtransusa.com/account/login.asp?p_url=/member/order_list.asp&p_query="
                          target="_blank"
                        >
                          Online Tools
                        </Link>
                      </li>
                      <li className="link-item">
                        <Link to="/contact-us" className="btn">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <button className="open_nav" onClick={toggleNav}>
                    <img src={BarsIcon} alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div
              className={
                mobileNav == false ? "mobile_header " : "mobile_header  show"
              }
            >
              <button className="cancel" onClick={toggleNav}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#000"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </button>
              <ul className="mobile_menus">
                <li className="menu_items">
                  <Link className="menu_links active_menu" to="/">
                    Home
                  </Link>
                </li>
                <li className="menu_items">
                  <Link className="menu_links" to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li className="menu_items">
                  <Link className="menu_links" to="/services">
                    Services
                  </Link>
                </li>
                <li className="menu_items">
                  <Link className="menu_links" to="/tools">
                    Tools
                  </Link>
                </li>
                <li className="menu_items">
                  <Link className="menu_links" to="/news">
                    News
                  </Link>
                </li>
                <li className="menu_items">
                  <Link className="menu_links" to="">
                    Online Tools
                  </Link>
                </li>
                <li className="menu_items">
                  <Link className="menu_links" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
      )}
    </>
  );
};
export default Header;
